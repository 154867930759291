import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ChatOptionModel } from '../../../models/chatoption.model';
import { TopicFileModel } from '../../../models/topicfile.model';
import { TopicModel } from '../../../models/topic.model';

@Component({
  selector: 'app-chat-chatinput',
  templateUrl: './chatinput.component.html',
  styleUrl: './chatinput.component.scss'
})
export class ChatinputComponent implements AfterViewInit {
  @ViewChild('textarea') textarea!: ElementRef<HTMLTextAreaElement>;
  @ViewChild('sendButton') sendButton!: ElementRef;
  @Input() selectedChatOption: ChatOptionModel | null = null;
  @Output() uploadTopicFiles = new EventEmitter<{ message: string; files: File[] }>();
  @Output() removeTopicFile = new EventEmitter<string>();
  selectedFiles: File[] = [];
  @Input() topic!: TopicModel;
  @Input() isSendingMessage!: boolean;
  newFileModel: TopicFileModel[] = [];
  showInvalidFileTypeAlert: boolean = false;
  ngAfterViewInit(): void {

    if (this.textarea && this.textarea.nativeElement) {
      const textareaElement = this.textarea.nativeElement;
      textareaElement.addEventListener('input', function () {
        this.style.height = 'auto';
        this.style.height = this.scrollHeight + 'px';
      });
    }
  }

  getFileModels() {
    if (this.topic && this.topic.topicFileModels && this.topic.topicFileModels.length > 0)
      return this.topic.topicFileModels;

    return this.newFileModel;
  }
  handleFileInput(event: Event) {
    this.showInvalidFileTypeAlert = false;
    const element = event.currentTarget as HTMLInputElement;
    let files: FileList | null = element.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (file && file.type === 'application/pdf') {
          this.selectedFiles.push(file);
          if (!(this.newFileModel.length > 0) && this.topic) {
            this.topic.topicFileModels!.push({ fileName: file.name, type: file.type });
          }
          else {
            this.newFileModel.push({ fileName: file.name, type: file.type });
          }
        }
        else {
          this.showInvalidFileTypeAlert = true;
          this.selectedFiles = [];
          break;
        }
      }
    }
  }

  removeFile(fileToRemove: string) {
    // const selectedFilesToRemove = this.selectedFiles.filter(file => file.name !== fileToRemove);
    const selectedFilesToRemove = this.selectedFiles.filter(file => file.name == fileToRemove);


    if (this.topic) {
      this.topic.topicFileModels! = this.topic.topicFileModels!.filter(meta => meta.fileName !== fileToRemove);
    }
    else {
      this.newFileModel = this.newFileModel.filter(meta => meta.fileName !== fileToRemove);
    }
    //it is part of the current selection andngas not been uploaded yet
    if (selectedFilesToRemove.length) {
      this.selectedFiles = this.selectedFiles.filter(file => file.name !== fileToRemove);
    }
    else {
      this.removeTopicFile.emit(fileToRemove);
    }
  }

  sendMessage() {
    if (this.isSendingMessage || !this.textarea.nativeElement.value.trim()) {
      return;
    }
    const message = this.textarea.nativeElement.value;
    this.uploadTopicFiles.emit({ message: message, files: this.selectedFiles });
    this.textarea.nativeElement.value = '';
    this.selectedFiles = [];
  }

  onTextareaKeydown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.sendButton.nativeElement.focus();
    } else if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.sendMessage();
    }
  }
}
