import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, firstValueFrom, take } from 'rxjs';
import { AppStateService } from '../../../../services/app-state.service';

@Component({
  selector: 'app-chat-newchat',
  templateUrl: './newchat.component.html',
  styleUrl: './newchat.component.scss'
})
export class NewchatComponent implements OnInit {
  serviceName: string | null = null;
 
  constructor(private route: ActivatedRoute,
    private appState: AppStateService,
    private router: Router) { }
  ngOnInit(): void {
  }

  navigateHome() {
    this.appState.appState.pipe(take(1)).subscribe(
      state => {
        this.router.navigateByUrl(state.serviceName ?? '/chat');
      }
    );    
  }
}
