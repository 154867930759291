<div class="d-flex justify-content-center h-100">
    <div class="container-fluid">
        <div class="row">
            @if(isSidebarVisible || !isMobileDevice()){
            <div id="sidebar" class="col-md-2 sidebar d-flex flex-column"
                [ngClass]="{'sidebar-overlay': true, 'sidebar-hidden': !isSidebarVisible}">
                <div class="fixed-top-section">
                    @if(!isMobileDevice()){
                    <app-logo />
                    }
                    @else{
                    <div class="mt-2"></div>
                    }
                    <app-user-profile />
                    <hr class="hr-divider">
                </div>
                <div class="scrollable-section">
                    <app-chat-sidebar />
                </div>
                <!--<app-chat-newchat />
                <app-chat-chathistory /> -->
            </div>
            }
            <div class="col-md-10 d-flex flex-column chat-container">
                <app-topbar [isMobileDevice]="isMobileDevice()" (toggleSidebar)="toggleSidebar()" />
                <router-outlet></router-outlet>
                <hr class="hr-divider">
                <div class="copyrightText text-center">
                    © 2024 Newmark Group, Inc. All rights reserved. NEWMARKGPT is a trademark of Newmark Group, Inc. <a
                        href="/assets/pdf/newmark-ai-policy-statement-2023-10-09.pdf" target="_blank">Accepted Use
                        Terms</a>
                </div>
            </div>
        </div>

    </div>
</div>